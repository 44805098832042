
import { defineComponent } from "vue";
import { RestResources } from "../../../rest-resource";
import ResourceList from "../../../components/resources/resources-list.vue";
import ProductDetails from "../products/product-details.vue";
import ResourceDropdown from "../../../components/resources/resource-dropdown.vue";
const ApiResources = RestResources();

export default defineComponent({
  name: "Products",
  components: {
    ResourceList,
    ProductDetails,
  },
  data() {
    return {
      rowData: [],
      loading: false,
      editType: "fullRow",
      frameworkComponents: {},
      paginationData: {},
      columnDefs: [
        {
          headerName: "Product Name",
          field: "name",
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Product Category",
          field: "product_category_id",
          valueGetter: (params: any) => {
            return params.data.productCategories.length
              ? params.data.productCategories[0].name
              : "";
          },
          valueSetter: (params: any) => {
            if (params.colDef.cellEditorParams.changedVal) {
              params.data.productCategories = {};
              params.data.productCategories[0].name =
                params.colDef.cellEditorParams.changedVal;
            }
            return true;
          },
          cellEditor: "ResourceDropdown",
          cellEditorParams: {
            resource: ApiResources.ProductCategories,
            field: "_id",
            changedVal: "",
            changedField: "name",
            page_size: this.getPageSize.bind(this),
          },
          sortable: true,
          filter: true,
          editable: true,
          resizable: true,
        },
        {
          headerName: "Updated Time",
          field: "updatedTime",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.updatedTime || "";
          },
        },
        {
          headerName: "Created Time",
          field: "createdTime",
          sortable: true,
          filter: true,
          resizable: true,
          valueGetter: (params: any) => {
            return params.data.createdTime || "";
          },
        },
        {
          headerName: "Actions",
          pinned: "right",
          width: 160,
          cellRenderer: this.renderActions.bind(this),
          cellStyle: { "text-align": "justify" },
        },
      ],
      btn_options: {
        button: "Add",
        icon: "fa fa-plus",
        class: "btn bg-theme float-end text-white",
      },
    };
  },
  async mounted() {
    this.frameworkComponents = {
      ResourceDropdown: ResourceDropdown,
    };
    this.loading = true;
    this.getProducts();
  },
  methods: {
    async getProducts(page = 1, search = "", pageLink = "", date: any = {}) {
      let url = pageLink
        ? pageLink
        : `${process.env.VUE_APP_ROOT_API}/products?page=${page}&page_size=${
            this.$store.state.page_size
          }${search ? `&search=${search}` : ""}${
            date.start ? `&created_time__gte=${date.start}` : ""
          }${date.end ? `&created_time__lte=${date.end}` : ""}`;
      try {
        let products = await ApiResources.getListResource(url).list();
        this.loading = false;
        let { result, ...paginationData } = products.response.data;
        this.rowData = result;
        this.paginationData = paginationData;
      } catch (err: any) {
        this.loading = false;
        if (err.response.status == 401) {
          this.displayMessage([
            {
              text: err.response.data.message,
              variant: "danger",
              timeoutMs: 5000,
              position: "top-0 end-0",
            },
          ]);
        }
      }
    },
    async editResource(data: any) {
      let updateResource = await ApiResources.Products.wrap(`/${data._id}`);
      let result = await updateResource.patch(data.resource);
      this.displayMessage([
        {
          text: result.data.message,
          variant: result.status == 200 ? "success" : "warning",
          timeoutMs: 5000,
          position: "top-0 end-0",
        },
      ]);
      this.getProducts();
    },
    getPageSize() {
      return this.$store.state.dropdown_page_size;
    },
    renderActions(params: any) {
      const eDiv = document.createElement("div");
      const showProduct: any = this.showProduct;
      const confirmDelete: any = this.confirmDelete;

      eDiv.innerHTML =
        '<span class="productCategories-actions"><button class="btn btn-simple-e" title="Edit Product Categories"><i class="fas fa-edit"></i></button><button class="btn btn-simple-d" title="Delete Product Categories"><i class="fas fa-trash-alt"></i></button></span>';
      var eButtonD = eDiv.querySelectorAll(".btn-simple-d")[0];

      var eButtonE = eDiv.querySelectorAll(".btn-simple-e")[0];
      eButtonE.addEventListener("click", function () {
        showProduct(params.data);
      });

      eButtonD.addEventListener("click", function () {
        confirmDelete(params.data);
      });
      return eDiv;
    },

    async confirmDelete(data: any) {
      this.showPopup({
        title: "Delete",
        text: `Are you sure, you want to delete ${data.name} ?`,
        confirmYes: "Delete",
        confirmNo: "Cancel",
        _id: data._id,
      });
    },
    async showProduct(data: any) {
      (this.$refs["resource-details"] as any).resetForm();
      let result;
      if (data) {
        let resourceEndPoint = await ApiResources.Products.wrap(`/${data._id}`);
        result = await resourceEndPoint.get();
      }
      (this.$refs["resource-details"] as any).showDetailModal(
        data ? result.data || {} : {}
      );
    },
    async deleteResource(_id: any) {
      let resourceEndPoint = await ApiResources.Products.wrap(`/${_id}`);
      let result = await resourceEndPoint.delete();
      this.displayMessage([
        {
          text: result.data.message,
          variant: result.status == 200 ? "success" : "warning",
          position: "top-0 end-0",
          timeoutMs: 5000,
        },
      ]);
      this.getProducts();
    },
  },
});
