import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "resource-detail" }
const _hoisted_2 = {
  type: "button",
  class: "btn btn-light d-none",
  "data-bs-toggle": "modal",
  "data-bs-target": "#exampleModal",
  ref: "submitBtn"
}
const _hoisted_3 = {
  class: "modal fade",
  id: "exampleModal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_4 = { class: "modal-dialog" }
const _hoisted_5 = { class: "modal-content" }
const _hoisted_6 = { class: "modal-header" }
const _hoisted_7 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_8 = { class: "modal-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_generator = _resolveComponent("form-generator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.title), 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_form_generator, {
                schema: _ctx.schema,
                submitButton: _ctx.submitButton,
                onUpdatedForm: _ctx.validForm,
                ref: "generatedform"
              }, null, 8, ["schema", "submitButton", "onUpdatedForm"])
            ])
          ])
        ])
      ])
    ])
  ]))
}