
import { defineComponent } from "vue";
import { requiredValidator } from "../../../validators";
import { RestResources } from "../../../rest-resource";
import ResourceDetail from "../../../components/resources/resource-details.vue";
const ApiResources = RestResources();

export default defineComponent({
  name: "product-details",
  components: {
    ResourceDetail,
  },
  data() {
    return {
      validatedForm: {},
      button: "Add",
      icon: "fa fa-plus",
      title: "Product Details",
      product: {},
      schema: [],
      submitButton: {
        class: "btn bg-theme bt-submit mt-3 float-start",
        value: "Submit",
        loading: "Submiting...",
      },
    };
  },
  methods: {
    async showDetailModal(data: any) {
      this.resetForm();
      let fields = await this.setFormData(data);
      this.schema.push(...fields);
      this.product = data;
      (this.$refs["ResourceDetail"] as any).toggleDetailModal();
    },
    resetForm() {
      this.schema.length = 0;
    },
    async onsubmit(data: any) {
      const proxy1 = new Proxy(data, {});
      const dataValues = JSON.parse(JSON.stringify(proxy1));
      dataValues.forEach((element: any) => {
        this.validatedForm[element.name] = element.value;
      });

      try {
        let result;
        let resourceEndPoint = ApiResources.Products.wrap(
          `/${this.product._id ? this.product._id : ""}`
        );
        if (this.product._id) {
          result = await resourceEndPoint.patch(this.validatedForm);
          this.clearForm(false);
        } else {
          result = await resourceEndPoint.post(this.validatedForm);
          this.clearForm();
        }
        this.displayMessage(
          result.data.message,
          result.status == 200 ? "success" : "warning"
        );
        this.$emit("getResources");
        (this.$refs["ResourceDetail"] as any).toggleDetailModal();
      } catch (err: any) {
        this.clearForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    clearForm(reset = true) {
      (this.$refs["ResourceDetail"] as any).$refs["generatedform"].resetForm(
        reset
      );
    },
    setFormData(data: any) {
      return [
        {
          fieldType: "InputField",
          placeholder: "Enter Name",
          label: "Name*",
          name: "name",
          type: "text",
          value: data.name ? data.name : "",
          class: "form-group col-md-12 mb-2 mt-3 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Name is required" },
          ],
        },
        {
          fieldType: "SelectField",
          placeholder: "Product Category",
          label: "Product Category*",
          name: `product_category_id`,
          value: data.product_category_id ? data.product_category_id : "",
          class: "form-group col-md-12 mb-2 mt-2 text-secondary",
          defaultValue: data.product_category_id
            ? data.product_category_id.name
            : "",
          changedField: "name",
          changedFieldKey: "_id",
          options: [],
          isResource: true,
          resource: ApiResources.ProductCategories,
          page_size: 10,
          validators: [
            {
              validator: requiredValidator,
              message: "Product Category is required",
            },
          ],
        },
      ];
    },
  },
});
