
//Resource detail is a reusable component used to add new details and edit
// details by generating form with requried fields.

import { defineComponent } from "vue";
import FormGenerator from "../forms/form-generator.vue"; // @ is an alias to /src

export default defineComponent({
  name: "ResourceDetail",
  components: {
    FormGenerator,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    submitButton: {
      type: Object,
      required: true,
    },
    button: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  methods: {
    async validForm(data: any) {
      this.$emit("onsubmit", data);
    },
    async toggleDetailModal() {
      this.$refs.submitBtn.click();
    },
    onClose() {
      (this.$refs["generatedform"] as any).resetForm();
    },
  },
});
